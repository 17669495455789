import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import { AUTH_TOKEN } from '@/vue-apollo'
import { auth } from '../utils/api'
import store from '../store'
import { translations } from '@/utils/common'

Vue.use(VueRouter)

const beforeEnter = async (to, from, next) => {
  // if the token or the store is not set then go to 'Login'
  if (Cookies.get(AUTH_TOKEN) === undefined || !store?.state?.auth?.userInfo?.account) {
    await auth.logout()
    return next({ name: 'Login' })
  }

  // if the user doesn't have any capabilities then go to 'ReferTo'
  if (to.name !== 'ReferTo' && store?.state?.auth?.userInfo?.account?.capabilities?.length === 0) {
    return next({ name: 'ReferTo' })
  }

  return next()
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },

  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/refer-to',
    name: 'ReferTo',
    component: () => import('../views/ReferTo.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/schedule',
    name: 'Schedule',
    meta: {
      family: 'Schedule',
      navBar: true,
      title: translations.scheduleTitle,
      icon: 'mdi-calendar-month',
      capability: ['Activity Planner']
    },
    component: () => import('../views/Schedule.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/planner',
    name: 'Planner',
    meta: {
      family: 'Planner',
      navBar: true,
      title: translations.longTermPlanner,
      icon: 'mdi-calendar-text',
      capability: ['Long Term Planner']
    },
    component: () => import('../views/PlannerView/PlannerView.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/back-office/:tab?',
    name: 'BackOffice',
    meta: {
      family: 'BackOffice',
      navBar: true,
      title: translations.backOfficeTitle,
      icon: 'mdi-clipboard-account-outline',
      capability: ['Back Office']
    },
    component: () => import('../views/BackOffice.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/clock-in',
    name: 'Reports',
    meta: {
      family: 'Reports',
      navBar: true,
      title: translations.clockInTitle,
      icon: 'mdi-clock-time-eleven-outline',
      capability: ['Reports']
    },
    component: () => import('../views/Reports.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/inert-clock-in',
    name: 'InertReports',
    meta: {
      family: 'InertReports',
      navBar: true,
      title: translations.inertClockInTitle,
      icon: 'mdi-clock-star-four-points-outline',
      capability: ['Admin']
    },
    component: () => import('../views/InertReports.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/time-reports',
    name: 'TimeReports',
    meta: {
      family: 'TimeReports',
      navBar: true,
      title: translations.timeReportsTitle,
      icon: 'mdi-account-clock-outline',
      capability: ['Back Office', 'Reports']
    },
    component: () => import('../views/TimeReports.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/employee-time-report',
    name: 'EmployeeTimeReport',
    meta: {
      family: 'TimeReports'
    },
    component: () => import('../views/EmployeeTimeReport.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/project-summary',
    name: 'ProjectSummary',
    meta: {
      family: 'ProjectSummary',
      navBar: true,
      title: translations.projectSummary,
      icon: 'mdi-briefcase-clock-outline',
      capability: ['Activity Planner', 'Back Office', 'Reports']
    },
    component: () => import('../views/ProjectSummary.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/plants',
    name: 'Plants',
    meta: {
      family: 'Piles',
      navBar: true,
      title: translations.managePiles,
      icon: 'mdi-image-filter-hdr-outline',
      capability: ['Manage piles']
    },
    component: () => import('../views/Plants'),
    beforeEnter: beforeEnter
  },
  {
    path: '/piles',
    name: 'Piles',
    meta: {
      family: 'Piles'
    },
    component: () => import('../views/Piles.vue'),
    beforeEnter: beforeEnter
  },
  {
    path: '/pile-movements',
    name: 'PileMovements',
    meta: {
      family: 'Piles'
    },
    component: () => import('../views/PileMovements.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/news',
    name: 'News',
    meta: {
      family: 'News',
      navBar: true,
      title: translations.newsTitle,
      icon: 'mdi-bulletin-board',
      capability: ['Manage news']
    },
    component: () => import('../views/News.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '/registry/:tab?',
    name: 'Registry',
    meta: {
      family: 'Registry',
      navBar: true,
      title: translations.registry,
      icon: 'mdi-archive-cog-outline',
      capability: ['Admin', 'Activity Planner', 'Back Office', 'Reports', 'Long Term Planner', 'Manage piles', 'Manage news']
    },
    component: () => import('../views/Registry.vue'),
    beforeEnter: beforeEnter
  },

  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = router.push
router.push = function push(location) {
  return originalPush.call(this, location).catch(e => e)
}

export default router
